<template>
  <div v-if="loaded == false" class="loading-bg">
    <img src="../assets/images/toast_loading.png" class="loading" alt="">
    <div class="loading-text">页面加载中…</div>
  </div>
  <div v-else class=" theme-container" :style="{ height: height + 'px' }">
    <div class="top-content">
      <div>
        <a style="color:#FFFFFF99;font-size:0.14rem;width: 0.6rem;" @click="back">退出编辑</a>
      </div>


      <div class="thumb">

        <div class="top_thumb">
          <index-content :theme="theme" :userInfo="userInfo" :id="selId" :choose="true" />
        </div>
        <div class="stop"></div>
      </div>
      <div>
        <a @click="preview" style="width:0.6rem;display: flex;flex-direction: row;">
          <div class="expand"></div>
          <SvgIcon name='preview' size="28" color="#fff" />
        </a>
      </div>

    </div>

    <div class="detail" v-if="groups.length > 0">
      <div class="detail-content" id="scroll-content">
        <div v-for="(categories) in groups[selIndex].categories" :key="categories.id">
          <div class="category">
            {{ categories.name }}
            <div class="line"></div>
          </div>
          <div class="detail_theme_row">
            <div v-for="(stylesObj) in categories.styles" :key="stylesObj.id">
              <div :class="stylesObj.id == selId ? 'sel-theme' : 'nor-theme'"
                :style="{ marginRight: categories.styles.length > 4 ? '0.12rem' : '0.2rem' }">
                <a class="detail_theme_bg" :style="{ width: scale * 100 + 'vw', height: scale * 100 + 'vh' }"
                  @click.stop="chooseTheme(stylesObj)">
                  <div class="detail_theme" :style="{ transform: `scale(${scale})` }">
                    <index-content :theme="stylesObj.styles" :userInfo="userInfo" :id="stylesObj.id" :choose="true" />
                  </div>
                  <div class="stop"></div>
                </a>
                <div class="check-bg" v-if="stylesObj.id == selId">
                  <img src="../assets/images/checked.png" style="width:0.2rem;height:0.2rem;" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="top">
        <div style="display:flex;flex-direction:row;flex-shrink:0">
          <a v-for="(group, index) in groups" :key="group.id" @click="chooseGroup(index)">
            <div :class="index == selIndex ? 'group-item group-item-sel' : 'group-item'">{{ group.name }}</div>
          </a>
        </div>
      </div>
      <div class="bottom">
        <a class="link_btn0" @click="save">保存</a>
      </div>
      <div class="left"></div>
      <div class="right"></div>
    </div>

  </div>
</template>

<script>
import IndexContent from './index_content.vue';
import SvgIcon from '../components/SvgIcon.vue';
import { get, post } from '../config/http';
import { isiOS } from '../config/utils';
import api from '../config/api';
import inobounce from 'inobounce';
export default {
  components: {
    IndexContent,
    SvgIcon
  },
  props: {
    msg: String
  },
  name: 'theme',
  data() {
    return {
      theme: null,
      loaded: false,
      selId: this.$route.query.selId,
      userInfo: JSON.parse(localStorage.getItem('socials')),
      // userInfo: global.userInfo,
      groups: [],
      selIndex: 0,
      scale: 60 / 375.0,
      height: document.documentElement.clientHeight,
    }
  },
  mounted() {
    if (this.height == localStorage.getItem('clientHeight') && this.isWeiXin() && this.isiOS()) {
      this.height = this.height - 80
    }
    var _this = this
    get(api.themeStyles).then(data => {
      var groups = data.themes;

      groups.map(item => {
        var index = 0;
        item.categories.map(item1 => {

          item1.styles.map(item2 => {
            index++;
            item2.index = 0;//index;
            var styles = item2.styles;
            _this.parseJson(styles);
            item2.styles = styles;
            if (item2.id == _this.selId) {
              _this.theme = styles
            }
          })
        })
      });

      _this.groups = groups
      _this.loaded = true

    });


    if (isiOS()) {
      inobounce.enable()
    }
  },

  beforeDestroy() {
    inobounce.disable()
    // const scrollview = document.getElementById('indexcontent')
    // scrollview.removeEventListener('scroll', this.scrollview, true)
  },
  methods: {
    isWeiXin() {
      //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
      var ua = window.navigator.userAgent.toLowerCase();
      //通过正则表达式匹配ua中是否含有MicroMessenger字符串
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true;
      } else {
        return false;
      }
    },
    isiOS() {
      var u = navigator.userAgent;
      return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    },
    back() {
      global.theme_alive = false
      this.$router.push('/').catch(err => { })
    },
    save() {
      global.theme_alive = false
      var _this = this
      post(api.userThemes, { theme_style_id: this.selId }).then(data => {
        if (global.index) {
          global.index.refresh()
        }
        _this.$router.back()
      })
    },
    chooseTheme(stylesObj) {
      this.theme = stylesObj.styles
      this.selId = stylesObj.id
    },
    preview() {
      if (this.userInfo.socials.length == 0) {
        this.$toast('添加链接后，即可预览', require('../assets/images/toast_tip.png'))
        return
      }
      this.$router.push({ path: '/preview', query: { theme: JSON.stringify(this.theme), isPreview: true } }).catch(err => { })
    },
    parseJson(jsonObj) {
      for (var key in jsonObj) {
        var element = jsonObj[key];
        // if ((element.length > 0 && typeof element == 'object') || typeof element == 'object') {
        if (typeof element == 'object' && !Array.isArray(element)) {
          this.parseJson(element);
        } else if (element.length > 0 && typeof element == 'string') {
          if (element.indexOf('#') != -1) {
            if (element.indexOf('#') != 0) {
              jsonObj[key] = element.replaceAll(/#([\w]{2})(\w{6})/ig, (match, p1, p2) => {
                return '#' + p2 + p1;
              })
            }
            else {
              jsonObj[key] = '#' + element.substring(3, 9) + element.substring(1, 3);
            }

          }
        }
      }
    },
    chooseGroup(index) {
      this.selIndex = index;
      const scrollview = document.getElementById('scroll-content')
      scrollview.scrollTo(0, 0)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
div {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.loading-bg {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .loading {
    width: 0.48rem;
    height: 0.48rem;
    animation: loading 1s 0s linear infinite;
  }

  .loading-text {
    margin-top: 0.1rem;
    font-size: 0.14rem;
    color: #fff;
  }

  @keyframes loading {

    // 0%{
    //   transform: rotate(0);
    // }
    100% {
      transform: rotate(360deg);
    }
  }
}

.theme-container {
  background-color: #131314;
  color: #fff;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  // height:90%;
  // bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-x: hidden;
  background: linear-gradient(360deg, #D5D5E0 0%, #131314 100%);
  overflow: hidden;
}

.top-content {
  display: flex;
  flex-direction: row;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  justify-content: space-between;
  width: 100vw;
  padding: 0 0.2rem;
  box-sizing: border-box;

  .thumb {
    box-shadow: 0px 19px 39px 0px rgba(0, 0, 0, 0.7);
    border-radius: 0.06rem;
    width: 30vw;
    height: 30vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    position: relative;
    // background-color: red;
    // position: relative;
    z-index: 10000;

  }

  .top_thumb {
    width: 100vw;
    height: 100vh;
    transform-origin: top center;
    transform: scale(0.3, 0.3);
  }
}

.detail_theme_row {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-y: hidden;
  width: 3.55rem;
  margin-bottom: 0.1rem;
  padding-bottom: 0.2rem;
  padding-left: 0.2rem;
}

.detail_theme_row::-webkit-scrollbar {
  display: none;
}

.detail_theme_bg {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  // 
  border-radius: 0.06rem;
  position: relative;
  z-index: 1;
  flex-shrink: 0;

  .detail_theme {
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    transform-origin: top center;
  }
}

.detail {
  display: flex;
  flex: 1;
  width: 100vw;
  background-color: #131314FF;
  border-radius: 0.24rem 0.24rem 0px 0px;
  position: relative;
  bottom: 0;
  box-sizing: border-box;
  overflow: hidden;
  z-index: 1;
  padding-bottom: 1rem;


  .detail-content {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-top: 0.68rem;
    // padding-left: 0.2rem;
    padding-bottom: 0.8rem;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    -webkit-overflow-scrolling: touch;
  }

  .category {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #74747AFF;
    font-size: 0.12rem;
    font-weight: bold;
    width: 3.35rem;
    margin-bottom: 0.1rem;
    margin-left: 0.2rem;
    margin-right: 0.2rem;

    .line {
      height: 1px;
      background-color: #2C2C2EFF;
      display: flex;
      margin-left: 0.08rem;
      flex: 1;
    }
  }

  .left {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 0.2rem;
    z-index: 10;
    background: linear-gradient(90deg, #131314 0%, rgba(19, 19, 20, 0) 100%);
  }

  .top {
    position: absolute;
    width: 3.75rem;
    z-index: 1;
    // right: 0;
    left: 0;
    top: 0;
    height: 0.68rem;
    border-radius: 0.24rem 0.24rem 0px 0px;
    background: linear-gradient(to bottom, #131314 80%, rgba(19, 19, 20, 0));
    overflow-x: scroll;
    box-sizing: border-box;
    padding-top: 0.14rem;
    padding-left: 0.2rem;
    display: flex;
    flex-direction: row;

    .group-item {
      margin-right: 0.24rem;
      display: flex;
      flex-shrink: 0;
      height: 0.32rem;
      color: #74747AFF;
      font-size: 0.14rem;
      box-sizing: border-box;
      display: flex;
      align-items: center;
    }

    .group-item-sel {
      color: #FFF700FF;
      padding: 0 0.12rem;
      border-radius: 0.16rem;
      border: 0.02rem solid #FFF700;
    }
  }

  .detail-content::-webkit-scrollbar {
    display: none;
  }

  .top::-webkit-scrollbar {
    display: none;
  }

  .right {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 0.2rem;
    z-index: 1;
    background: linear-gradient(90deg, rgba(19, 19, 20, 0) 0%, #131314 100%);
  }

  .bottom {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    height: 1.1rem;
    background: linear-gradient(180deg, rgba(19, 19, 20, 0) 0%, #131314 20%);
    box-sizing: border-box;
    padding-top: 0.34rem;
    display: flex;
    justify-content: center;
    z-index: 10;
  }
}

.stop {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: transparent;
}

.detail::-webkit-scrollbar {
  display: none;
}

.sel-theme {
  border: solid 2px #FFF700FF;
  border-radius: 0.09rem;
  margin-right: 0.2rem;
  padding: 0.02rem;
  position: relative;

  .check-bg {
    position: absolute;
    bottom: -0.1rem;
    left: 0;
    right: 0;
    align-items: center;
    height: 0.2rem;
    z-index: 1;
  }
}

.nor-theme {
  border: solid 0.02rem transparent;
  border-radius: 0.09rem;
  padding: 0.02rem;
  margin-right: 0.20rem;
}
</style>